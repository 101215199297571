<template>
  <div class="misc-wrapper">
    <span class="brand-logo">
      <img style="max-height:39px;" src="/shield-purple.svg" class="img-fluid" alt="Brand logo">
      <h2 class="brand-text text-primary ml-1" style="margin-top:8px;">
        Secris
      </h2>
    </span>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          You are not authorized! 🔐
        </h2>
        <p class="mb-2">
          You don’t have permission to access this page. Your permissions may have been changed.
        </p>
        <b-button
          variant="primary"
          class="mb-1 mr-1 btn-sm-block"
          :to="loginRoute()"
        >Back to Home</b-button>

        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          @click="logout()"
        >Log Out</b-button>

        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton } from 'bootstrap-vue'
import store from '@/store'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BImg, BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    loginRoute() {
      return '/';
    },
    logout() {
      // Remove userData from store
      this.$store.dispatch('user/clearUser')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
